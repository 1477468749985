<template>
  <div class="wrapper">
    <TreeToVliew ref="treeView"></TreeToVliew>
    <right-content>
      <div class="right-content_head">
        <el-button @click="createSupervison">创建监督岗</el-button>
        <el-button @click="exportData">导出监督岗</el-button>
      </div>
      <div class="right-content_table">
        <span class="title">{{ departmentName }}:监督岗{{ total }}个</span>
        <div class="table">
          <my-table
            @currentPage="currentPage"
            :tableInfo="tableInfo"
            :colConfigs="colConfigs"
          >
            <el-table-column
              width="300px"
              align="center"
              slot="option"
              label="操作"
            >
              <template slot-scope="{ row }">
                <div class="option">
                  <el-button
                    icon="iconfont icon-chakan-copy1"
                    @click="check(row)"
                    >查看</el-button
                  >
                  <el-button icon="iconfont icon-bianji1" @click="change(row)"
                    >编辑</el-button
                  >
                  <el-button icon="iconfont icon-shanchu11" @click="del(row)"
                    >删除</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </my-table>
        </div>
      </div>
    </right-content>
  </div>
</template>

<script>
import MyTable from "../../components/MyTable";
import { getSentryList, delInformation,exportSentry } from "@/api";
import TreeToVliew from "@/views/commonViews/TreeToVliew";
import RightContent from "../commonViews/RightContent";
import {exportFun} from '@/utils/params'
export default {
  name: "supervision",
  components: { RightContent, MyTable, TreeToVliew },
  created() {
    this.getSentrysData();
  },
  async activated () {
    await this.getSentrysData();
  },
  methods: {
     exportData(){
      const param = {
        type: 3,
        department_id: localStorage.getItem("department_id")
      }
      exportFun(exportSentry,param,"exportSentry")
    },
    createSupervison() {
      this.$router.push({
        path: "/supervision/supervisionCreate",
      });
    },
    currentPage(current) {
      this.page = current;
      this.getSentrysData();
    },
    async getSentrysData() {
      const res = await getSentryList({
        department_id: localStorage.getItem("department_id"),
        page: this.page
      });
      if (res.status === 200) {
        this.tableInfo = res.data.data;
        this.total = res.data.data.total;
      }
    },
    change(row) {
      this.$router.push({
        path: "/supervision/supervisionChange",
        query: {
          info: JSON.stringify(row),
        },
      });
    },
    check(row) {
      this.$router.push({
        path: "/supervision/supervisionDetail",
        query: {
          info: JSON.stringify(row),
        },
      });
    },
    async del({ id }) {
      try {
        const flag = await this.$open({ msg: "确定删除项目部？" });
        if (flag) {
          const res = await delInformation({
            type: 3,
            del_type: 2,
            id,
          });
          if (res.status === 200) {
            this.$success("删除成功");
            await this.getSentrysData();
            this.$refs.treeView.renderTreeList();
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  data() {
    return {
      departmentName:JSON.parse(localStorage.getItem("userInfo")).name,
      page:1,
      total:0,
      tableInfo: {},
      colConfigs: [
        { prop: "name", label: "监督岗名称" },
        { prop: "found_time", label: "设立时间" },
        { prop: "people_name", label: "岗长" },
        { prop: "people_number", label: "联系电话" },
        { slot: "option" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: flex;
  .right-content_head {
    margin-bottom: 20px;
    button {
      background-color: red;
      color: #fff;
      padding: 10px 25px;
    }
  }
  .right-content_table {
    .table {
      margin: 20px 0;
    }
    button {
      padding: 7px 15px;
      background: #fde5e4;
      border-radius: 4px;
      border: 0;
      font-size: 15px;
      font-family: PingFang SC;
      color: #e32322;
    }
  }
}
</style>
